<template>
  <table class="bulk-payments">
    <tr>
      <th class="text-xs-center">Valid</th>
      <th class="text-xs-center">Address</th>
      <th class="text-xs-center">Amount</th>
      <th class="text-xs-center">Memo</th>
      <th class="text-xs-center" v-if="bulkPayment.hasErrors">Errors</th>
    </tr>
    <tr v-for="(item, index) in bulkPayment.payments" v-bind:key="`p-${index}`">
      <td class="text-xs-center">
        <v-icon v-if="item.hasErrors" color="error">error</v-icon>
        <v-icon v-else color="success">check_circle</v-icon>
      </td>
      <td class="text-xs-center">{{ item.address }}</td>
      <td class="text-xs-center">{{ item.amount }}</td>
      <td class="text-xs-center">{{ item.memo }}</td>
      <td class="text-xs-left" v-if="bulkPayment.hasErrors">
        <ul v-if="item.errors" class="errors">
          <li v-for="(error, ei) in item.errors" v-bind:key="`e-${index}-${ei}`">
            {{ error }}
          </li>
        </ul>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  props: ['bulkPayment'],
};
</script>

<style>
table.bulk-payments {
  width: 100%;
}
table.bulk-payments > tr {
  border: 1px lightgray solid;
}
</style>
