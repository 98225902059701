<template>
  <ba-dialog
    :show="dialog"
    v-on:show-dialog="dialog = true"
    btnTooltip="View Payment Details"
    :btn-outline="true"
    btn-name="View"
    title="Bulk Payment Details"
  >
    <v-progress-linear v-if="isLoading" slot="progress" color="accent" height="2" indeterminate></v-progress-linear>
    <v-layout row wrap v-else>
      <v-flex xs12 v-if="bulkPayment.paymentTransactionId" pb-4>
        <v-alert :value="true" type="success">
          <h3>Paid</h3>
          TransactionId:
          <a :href="transactionExplorerUrl" target="_blank">{{ transactionId }}</a>
          <v-icon size="14">open_in_new</v-icon>
        </v-alert>
      </v-flex>
      <v-flex xs12>
        <bulk-payment-details :bulk-payment="bulkPayment.bulkPayment" />
      </v-flex>
    </v-layout>
  </ba-dialog>
</template>

<script>
import gql from 'graphql-tag';

import { baConfig } from '../../../config';
import BulkPaymentDetails from './BulkPaymentDetails';

export default {
  components: { BulkPaymentDetails },
  props: ['id'],
  data() {
    return {
      dialog: false,
      isLoading: false,
      bulkPayment: {},
    };
  },
  apollo: {
    bulkPayment: {
      query: gql`
        query bulkPayment($orgId: ID!, $id: ID!) {
          bulkPayment(orgId: $orgId, id: $id) {
            id
            created
            createdBy
            status
            paymentTransactionId
            bulkPayment {
              name
              total
              coin
              metaErrors
              payments {
                address
                amount
                memo
                errors
              }
            }
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
          id: this.id,
        };
      },
      skip() {
        const active = this.dialog && this.id;
        return !active;
      },
      loadingKey: 'isLoading',
    },
  },
  computed: {
    transactionId() {
      return this.bulkPayment.paymentTransactionId;
    },
    transactionExplorerUrl() {
      if (baConfig.etherenetExplorerUrl) {
        return baConfig.etherenetExplorerUrl + this.transactionId;
      } else {
        return 'https://etherscan.io/tx/' + this.transactionId;
      }
    },
  },
};
</script>
