<!-- https://front.id/en/articles/vuetify-achieve-multiple-filtering-data-table-component -->
<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-card class="pa-2">
        <v-layout>
          <v-flex xs2>
            <create-bulk-payment @refresh="refresh" v-if="checkScope(scopeLiterals.BillPaymentsCreate)" />
          </v-flex>
          <v-flex xs10></v-flex>
        </v-layout>
      </v-card>
    </v-flex>
    <v-flex xs12>
      <v-data-table
        :headers="headers"
        :items="bulkPayments"
        item-key="id"
        :loading="isLoading ? 'accent' : false"
        :pagination.sync="pagination"
        class="elevation-3"
        :rows-per-page-items="[10, 50, 100, { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }]"
        ref="bulkPaymentsTable"
      >
        <template slot="items" slot-scope="br">
          <tr>
            <td class="text-xs-center">
              {{ toLocalDateTime(br.item.created) }}
            </td>
            <td class="text-xs-center">
              {{ br.item.bulkPayment.name }}
            </td>
            <td class="text-xs-center">
              {{ br.item.createdBy }}
            </td>
            <td class="text-xs-center">
              {{ br.item.bulkPayment.coin }}
            </td>
            <td class="text-xs-center">
              {{ br.item.bulkPayment.total }}
            </td>
            <td class="text-xs-center">
              {{ br.item.status }}
            </td>
            <td class="text-xs-center">
              <a
                v-if="br.item.paymentTransactionId"
                :href="getExplorerLink(br.item.paymentTransactionId)"
                target="_blank"
              >
                {{ preview(br.item.paymentTransactionId, 10) }}
              </a>
            </td>
            <td class="text-xs-left">
              <pay-bulk-payment :id="br.item.id" @refresh="refresh" v-if="br.item.status === 'Draft'" />
              <view-bulk-payment-details :id="br.item.id" />
              <v-btn text icon @click="deleteItem(br.item.id)" v-if="br.item.status === 'Draft'">
                <v-icon>delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import gql from 'graphql-tag';
import moment from 'moment';

import { baConfig } from '../../../config';
import { MUT_SNACKBAR } from '../../store';
import CreateBulkPayment from './CreateBulkPayment';
import PayBulkPayment from './PayBulkPayment';
import ViewBulkPaymentDetails from './ViewBulkPaymentDetails';

export default {
  props: [],
  components: { PayBulkPayment, CreateBulkPayment, ViewBulkPaymentDetails },
  data: () => ({
    bulkPayments: [],
    pagination: {
      sortBy: 'created',
      descending: true,
    },
    headers: [
      {
        text: 'Created',
        align: 'center',
        value: 'creatd',
      },
      {
        text: 'Name',
        align: 'center',
        value: 'bulkPayment.name',
      },
      {
        text: 'Created By',
        align: 'center',
        value: 'createdBy',
      },
      {
        text: 'Coin',
        align: 'center',
        value: 'coin',
      },
      {
        text: 'Total',
        align: 'center',
        value: 'total',
      },
      {
        text: 'Status',
        align: 'center',
        value: 'status',
      },
      {
        text: 'Txn',
        align: 'center',
        value: 'paymentTransactionId',
      },
      {
        text: '',
        value: '',
      },
    ],
    isLoading: 0,
    selected: [],
    contacts: [],
    categories: [],
  }),
  apollo: {
    bulkPayments: {
      query: gql`
        query bulkPayments($orgId: ID!) {
          bulkPayments(orgId: $orgId) {
            id
            created
            createdBy
            status
            paymentTransactionId
            bulkPayment {
              name
              total
              coin
            }
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      // update(data) {
      //   return data.bulkPayments;
      // },
      loadingKey: 'isLoading',
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    getExplorerLink(txnId) {
      if (baConfig.etherenetExplorerUrl) {
        return baConfig.etherenetExplorerUrl + txnId;
      } else {
        return 'https://etherscan.io/tx/' + txnId;
      }
    },
    preview(str, n) {
      if (str) {
        return str.substring(0, n);
      } else {
        return '';
      }
    },
    async deleteItem(id) {
      const res = await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation ($orgId: ID!, $id: ID!) {
            deleteBulkPayment(orgId: $orgId, id: $id) {
              success
              errors
            }
          }
        `,
        // Parameters
        variables: {
          orgId: this.$store.state.currentOrg.id,
          id: id,
        },
      });

      if (res.data && res.data.deleteBulkPayment && res.data.deleteBulkPayment.success) {
        this.$store.commit(MUT_SNACKBAR, {
          color: 'success',
          message: 'Bulk Payment Deleted',
        });

        this.refresh();
      } else {
        let message = 'Problem Deleting Payment: ';
        if (res.data.bulkPayments && res.data.bulkPayments.errors) {
          message = message + res.data.bulkPayments.errors.join(', ');
        }

        this.$store.commit(MUT_SNACKBAR, {
          color: 'error',
          message,
        });
      }
    },
    refresh() {
      this.$apollo.queries.bulkPayments.refetch();
    },
    toLocalDateTime(timestamp) {
      return moment.unix(timestamp).local().format('lll');
      // return moment(timestamp, "X")
      //   .utc()
      //   .format("lll");
    },
  },
};
</script>
