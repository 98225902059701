




















































































































import gql from 'graphql-tag';
import * as math from 'mathjs';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { asDefined, assertDefined } from '@/utils/guards';

import { baConfig } from '../../../config';
import type { BulkPaymentRecord, FeeEstimate, Wallet } from '../../api-svc-types';
import { BaseVue } from '../../BaseVue';
import { BitwaveMultiSendContract } from '../../contracts/bitwaveMultisendContract';
import { orchidAbi, orchidAddress } from '../../contracts/orchid';
import { MUT_SNACKBAR } from '../../store';
import { stringifyError } from '../../utils/error';
import { approveOxt, multiSendOxt } from '../../utils/hardwareDevices';
import BulkPaymentDetails from './BulkPaymentDetails.vue';

@Component({
  components: {
    BulkPaymentDetails,
  },
  apollo: {
    wallets: {
      query: gql`
        query wallets($orgId: ID!) {
          wallets(orgId: $orgId) {
            id
            name
            type
            path
            address
            balance {
              totalFiatValue {
                displayValue
                currency
              }
            }
            bulkSend {
              enabled
              ethereumMultiSendContractAddress
            }
            enabledCoins
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      update(data) {
        return data.wallets;
      },
      skip() {
        const active = this.dialog;
        return !active;
      },
      loadingKey: 'isLoading',
    },
    bulkPayment: {
      query: gql`
        query bulkPayment($orgId: ID!, $id: ID!) {
          bulkPayment(orgId: $orgId, id: $id) {
            id
            created
            createdBy
            status
            bulkPayment {
              name
              total
              coin
              metaErrors
              payments {
                address
                amount
                memo
                errors
              }
            }
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
          id: this.id,
        };
      },
      skip() {
        const active = this.dialog && this.id;
        return !active;
      },
      loadingKey: 'isLoading',
    },
    feeEstimates: {
      query: gql`
        query feeEstimates($coin: Coins!) {
          feeEstimates(coin: $coin) {
            id
            name
            feeUnit
            coin
            value
          }
        }
      `,
      loadingKey: 'isLoading',
      variables() {
        return {
          coin: 'ETH',
        };
      },
      skip() {
        const active = this.dialog;
        return !active;
      },
      fetchPolicy: 'no-cache',
    },
  },
})
export default class PayBulkPayment extends BaseVue {
  @Prop()
  readonly id!: string;

  isLoading = false;
  dialog = false;
  selectedWallet: Wallet | null = null;
  stage = 'start';
  tokenApproving = false;
  paymentSending = false;
  approvalFees = 'N/A';
  ethPaymentFees = 'N/A';
  fee = 0;
  gasLimit = 0;
  transactionId?: string;
  ercPaymentFees?: string;

  wallets: Wallet[] = [];
  bulkPayment!: BulkPaymentRecord;
  feeEstimates: FeeEstimate[] = [];

  get transactionExplorerUrl() {
    if (baConfig.etherenetExplorerUrl) {
      return baConfig.etherenetExplorerUrl + this.transactionId;
    } else {
      return 'https://etherscan.io/tx/' + this.transactionId;
    }
  }

  get filteredWallets() {
    return this.wallets.filter((m) => m.bulkSend && m.bulkSend.enabled);
  }

  async calcApprovalFees() {
    const web3 = this.web3();
    assertDefined(this.selectedWallet);
    const orchid = new web3.eth.Contract(orchidAbi, orchidAddress, {
      from: this.selectedWallet.address ?? undefined,
    });

    const bp = this.bulkPayment.bulkPayment;

    const approveValueInWei = web3.utils.toWei(bp.total.toString(), 'ether');

    assertDefined(this.selectedWallet.bulkSend);
    const action = orchid.methods.approve(
      this.selectedWallet.bulkSend.ethereumMultiSendContractAddress,
      approveValueInWei
    );
    const gasEstimate = await action.estimateGas();
    const gwei = gasEstimate * this.fee;
    const ethFees = web3.utils.fromWei(gwei.toString(), 'gwei');
    this.approvalFees = ethFees;
    this.gasLimit = math
      .bignumber(gasEstimate * 1.2)
      .toDecimalPlaces(0)
      .toNumber();
  }

  async calcEthPaymentFees() {
    const web3 = this.web3();
    const bp = this.bulkPayment.bulkPayment;
    assertDefined(bp.payments);
    const toValuesEth = bp.payments.map((m) => m!.amount);
    const toAddresses = bp.payments.map((m) => m!.address);

    const toValuesInWei = toValuesEth.map((m) => web3.utils.toWei(m.toString(), 'ether'));

    const valueInWei = web3.utils.toWei(bp.total.toString(), 'ether');

    const multisendContract = new web3.eth.Contract(
      BitwaveMultiSendContract.abi,
      this.selectedWallet?.bulkSend?.ethereumMultiSendContractAddress ?? undefined,
      {
        from: this.selectedWallet?.address ?? undefined,
      }
    );
    const action = multisendContract.methods.sendEth(toAddresses, toValuesInWei);

    const gasEstimate = await action.estimateGas({ value: valueInWei });
    const gwei = gasEstimate * this.fee;
    const ethFees = web3.utils.fromWei(gwei.toString(), 'gwei');
    this.gasLimit = math
      .bignumber(gasEstimate * 1.2)
      .toDecimalPlaces(0)
      .toNumber();
    this.ethPaymentFees = ethFees;
  }

  async calcErc20PaymentFees() {
    const bp = this.bulkPayment.bulkPayment;
    assertDefined(bp.payments);
    assertDefined(this.selectedWallet?.bulkSend?.ethereumMultiSendContractAddress);
    assertDefined(this.selectedWallet.address);

    const web3 = this.web3();
    const toValuesEth = bp.payments.map((m) => m!.amount);
    const toAddresses = bp.payments.map((m) => m!.address);

    const toValuesInWei = toValuesEth.map((m) => web3.utils.toWei(m.toString(), 'ether'));
    const multisendContract = new web3.eth.Contract(
      BitwaveMultiSendContract.abi,
      this.selectedWallet.bulkSend.ethereumMultiSendContractAddress,
      {
        from: this.selectedWallet.address,
      }
    );
    const action = multisendContract.methods.sendErc20(orchidAddress, toAddresses, toValuesInWei);

    const gasEstimate = await action.estimateGas();
    const gwei = gasEstimate * this.fee;
    const ethFees = web3.utils.fromWei(gwei.toString(), 'gwei');
    this.ercPaymentFees = ethFees;
    this.gasLimit = math
      .bignumber(gasEstimate * 1.2)
      .toDecimalPlaces(0)
      .toNumber();
  }

  async next1() {
    const tokens = new Set(['OXT', 'USDC']);
    if (this.bulkPayment.bulkPayment.coin === 'ETH') {
      await this.calcEthPaymentFees();
      this.stage = 'preview';
    } else if (tokens.has(this.bulkPayment.bulkPayment.coin)) {
      // check if approved
      const payoutApproved = await this.isMultisendApproved();
      if (payoutApproved) {
        // if so, skippity skip
        this.stage = 'transferApproved';
      } else {
        await this.calcApprovalFees();
        this.stage = 'preview';
      }
    }
  }

  getBulkSendTokenDetails() {
    const tokens = new Map([
      [
        'OXT',
        {
          address: '0x4575f41308EC1483f3d399aa9a2826d74Da13Deb',
          decimals: 18,
        },
      ],
      ['USDC', { address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', decimals: 6 }],
    ]);
    const t = tokens.get(this.bulkPayment.bulkPayment.coin);
    if (t === undefined) {
      throw new Error('Unable to find and map token');
    }

    return t;
  }

  async isMultisendApproved() {
    assertDefined(this.selectedWallet?.bulkSend?.ethereumMultiSendContractAddress);
    const web3 = this.web3();
    const t = this.getBulkSendTokenDetails();
    const tokenContract = new web3.eth.Contract(orchidAbi, t.address);
    const multisendAddress = this.selectedWallet.bulkSend.ethereumMultiSendContractAddress;
    const fromAddress = this.selectedWallet.address;

    const allowance = await tokenContract.methods.allowance(fromAddress, multisendAddress).call();
    // convert total to allowance units
    const totalInTokenUnits = math.bignumber(this.bulkPayment.bulkPayment.total).mul(10 ** t.decimals);
    const allowed = totalInTokenUnits.lte(allowance);
    return allowed;
  }

  async payEth() {
    assertDefined(this.selectedWallet?.bulkSend?.ethereumMultiSendContractAddress);
    assertDefined(this.bulkPayment.bulkPayment.payments);
    assertDefined(this.selectedWallet.address);
    this.paymentSending = true;
    const multisendAddress = this.selectedWallet.bulkSend.ethereumMultiSendContractAddress;
    const fromAddress = this.selectedWallet.address;
    const toAddresses = this.bulkPayment.bulkPayment.payments.map((m) => m!.address);
    const toValuesEth = this.bulkPayment.bulkPayment.payments.map((m) => m!.amount);

    try {
      const transactionId = await this.execMultiSendEth(multisendAddress, toValuesEth, toAddresses, fromAddress);

      await this.completePayment(transactionId);
      this.transactionId = transactionId;
      this.stage = 'paymentComplete';
    } catch (e) {
      this.$store.commit(MUT_SNACKBAR, {
        color: 'error',
        message: 'Problem sending payment: ' + stringifyError(e),
      });
    }

    this.paymentSending = false;
  }

  async execMultiSendEth(contractAddress: string, toValuesEth: string[], toAddresses: string[], fromAddress: string) {
    const web3 = this.web3();

    const toValuesInWei = toValuesEth.map((m) => web3.utils.toWei(m.toString(), 'ether'));

    const multisendContract = new web3.eth.Contract(BitwaveMultiSendContract.abi, contractAddress);
    const action = multisendContract.methods.sendEth(toAddresses, toValuesInWei);
    const gasPriceInWei = web3.utils.toWei(this.fee.toString(), 'gwei');
    const bp = this.bulkPayment.bulkPayment;
    const valueInWei = web3.utils.toWei(bp.total.toString(), 'ether');
    const v = web3.utils.toHex(valueInWei);
    const sending = action.send({
      from: fromAddress,
      gasPrice: gasPriceInWei,
      gas: this.gasLimit,
      value: v,
    });

    const { transactionHash } = await new Promise((resolve, reject) => {
      sending.on('error', reject).on('receipt', resolve);
    });

    return transactionHash as string;
  }

  async approveToken(
    tokenAddress: string,
    total: string,
    fromAddress: string,
    multiSendContractAddress: string,
    gasLimit: number,
    gasPrice: number
  ) {
    const web3 = this.web3();
    // const nonce = await web3.eth.getTransactionCount(fromAddress);

    // const valueInWei = web3.utils.toWei(total, "ether");
    const t = this.getBulkSendTokenDetails();
    const totalInTokenUnits = math
      .bignumber(this.bulkPayment.bulkPayment.total)
      .mul(10 ** t.decimals)
      .toString();

    const tokenContract = new web3.eth.Contract(orchidAbi, t.address, {
      from: fromAddress,
    });

    assertDefined(this.selectedWallet?.bulkSend);
    const action = tokenContract.methods.approve(
      this.selectedWallet.bulkSend.ethereumMultiSendContractAddress,
      totalInTokenUnits
    );

    const gasPriceInWei = web3.utils.toWei(gasPrice.toString(), 'gwei');
    const sending = action.send({
      from: fromAddress,
      gasPrice: gasPriceInWei,
      value: web3.utils.toHex(web3.utils.toWei('0', 'ether')),
    });

    const { transactionHash } = await new Promise((resolve, reject) => {
      sending.on('error', reject).on('receipt', resolve);
    });

    return transactionHash as string;
  }

  async approveOxt() {
    assertDefined(this.selectedWallet?.bulkSend?.ethereumMultiSendContractAddress);
    assertDefined(this.selectedWallet.path);
    assertDefined(this.selectedWallet.address);
    this.tokenApproving = true;
    const multisendAddress = this.selectedWallet.bulkSend.ethereumMultiSendContractAddress;

    const res = await approveOxt(
      this.selectedWallet.path,
      this.bulkPayment.bulkPayment.total,
      this.selectedWallet.address,
      multisendAddress,
      this.gasLimit,
      this.fee
    );
    if (res.success) {
      await this.calcErc20PaymentFees();
      this.stage = 'transferApproved';
      this.tokenApproving = false;
    } else {
      this.tokenApproving = false;
      this.$store.commit(MUT_SNACKBAR, {
        color: 'error',
        message: 'Problem approving OXT transfer: ' + res.error,
      });
    }
  }

  async payToken() {
    let tokenAddress;
    if (this.bulkPayment.bulkPayment.coin === 'USDC') {
      // TODO clean up with nice map and shit
      tokenAddress = '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48';
    } else {
      throw new Error('Unable to map token: ' + this.bulkPayment.bulkPayment.coin);
    }

    assertDefined(this.selectedWallet?.bulkSend);
    assertDefined(this.bulkPayment.bulkPayment.payments);
    assertDefined(this.selectedWallet.bulkSend.ethereumMultiSendContractAddress);
    assertDefined(this.selectedWallet.address);
    this.paymentSending = true;
    const web3 = this.web3();
    const multiSendContractAddress = this.selectedWallet.bulkSend.ethereumMultiSendContractAddress;

    const fromAddress = this.selectedWallet.address;
    const toAddresses = this.bulkPayment.bulkPayment.payments.map((m) => m!.address);
    const toValuesEth = this.bulkPayment.bulkPayment.payments.map((m) => m!.amount);

    const token = this.getBulkSendTokenDetails();
    const toValuesInWei = toValuesEth.map((m) => {
      return math
        .bignumber(m)
        .mul(10 ** token.decimals)
        .toString();
    });

    const multisendContract = new web3.eth.Contract(BitwaveMultiSendContract.abi, multiSendContractAddress, {
      from: fromAddress,
    });
    const action = multisendContract.methods.sendErc20(tokenAddress, toAddresses, toValuesInWei);
    const gasPriceInWei = web3.utils.toWei(this.fee.toString(), 'gwei');
    const sending = action.send({
      from: fromAddress,
      gasPrice: gasPriceInWei,
      value: web3.utils.toHex(web3.utils.toWei('0', 'ether')),
    });

    try {
      const { transactionHash } = await new Promise((resolve, reject) => {
        sending.on('error', reject).on('receipt', resolve);
      });
      await this.completePayment(transactionHash);
      this.paymentSending = false;
      this.transactionId = transactionHash;
      this.stage = 'paymentComplete';
    } catch (e) {
      this.$store.commit(MUT_SNACKBAR, {
        color: 'error',
        message: 'Problem sending payment: ' + stringifyError(e),
      });
    }
  }

  async approveUsdc() {
    assertDefined(this.selectedWallet?.bulkSend?.ethereumMultiSendContractAddress);
    assertDefined(this.selectedWallet.address);
    this.tokenApproving = true;
    const multisendAddress = this.selectedWallet.bulkSend.ethereumMultiSendContractAddress;
    const fromAddress = this.selectedWallet.address;
    try {
      const res = await this.approveToken(
        '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        this.bulkPayment.bulkPayment.total,
        fromAddress,
        multisendAddress,
        this.gasLimit,
        this.fee
      );
      this.stage = 'transferApproved';
      this.tokenApproving = false;
    } catch (e) {
      this.tokenApproving = false;
      this.$store.commit(MUT_SNACKBAR, {
        color: 'error',
        message: 'Problem approving OXT transfer: ' + stringifyError(e),
      });
    }
  }

  async payOxt() {
    this.paymentSending = true;
    assertDefined(this.selectedWallet?.bulkSend?.ethereumMultiSendContractAddress);
    assertDefined(this.bulkPayment.bulkPayment.payments);
    assertDefined(this.selectedWallet.path);
    assertDefined(this.selectedWallet.address);
    const multisendAddress = this.selectedWallet.bulkSend.ethereumMultiSendContractAddress;
    const fromAddress = this.selectedWallet.address;
    const toAddresses = this.bulkPayment.bulkPayment.payments.map((m) => m!.address);
    const toValuesEth = this.bulkPayment.bulkPayment.payments.map((m) => m!.amount);
    const res = await multiSendOxt(
      this.selectedWallet.path,
      this.bulkPayment.bulkPayment.total,
      fromAddress,
      multisendAddress,
      toAddresses,
      toValuesEth,
      this.gasLimit,
      this.fee
    );

    if (res.success) {
      await this.completePayment(asDefined(res.transactionId));
      this.paymentSending = false;
      this.transactionId = res.transactionId;
      this.stage = 'paymentComplete';
    } else {
      this.$store.commit(MUT_SNACKBAR, {
        color: 'error',
        message: 'Problem sending payment: ' + res.error,
      });
    }
  }

  closeDialog() {
    this.$emit('refresh');
    this.dialog = false;
  }

  async completePayment(transactionId: string) {
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      id: this.id,
      bulkPaymentStatus: 'Paid',
      paymentTransactionId: transactionId,
    };

    try {
      const res = await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation ($orgId: ID!, $id: ID!, $bulkPaymentStatus: BulkPaymentStatus, $paymentTransactionId: String) {
            updateBulkPayment(
              orgId: $orgId
              id: $id
              bulkPaymentStatus: $bulkPaymentStatus
              paymentTransactionId: $paymentTransactionId
            ) {
              success
              errors
            }
          }
        `,
        // Parameters
        variables: vars,
      });

      if (res.data) {
        if (res.data.updateBulkPayment) {
          if (res.data.updateBulkPayment.success) {
            this.$store.commit(MUT_SNACKBAR, {
              color: 'success',
              message: 'Payment Complete',
            });
          }
        }
      } else {
        this.$store.commit(MUT_SNACKBAR, {
          color: 'error',
          message: 'Problem updating payment: ' + stringifyError(res.errors),
        });
      }
    } catch (e) {
      console.log(e);
    }
  }
}
