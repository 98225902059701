<template>
  <div>
    <ba-dialog
      :show="dialog"
      v-on:show-dialog="dialog = true"
      btnIcon="add"
      btnTooltip="Create Bulk Payment"
      title="New Bulk Payment"
    >
      <v-container fluid grid-list-xl>
        <v-layout v-if="isLoading" row wrap>
          <v-flex xs12>
            <v-progress-linear indeterminate color="green" />
          </v-flex>
        </v-layout>
        <template v-else>
          <v-layout v-if="stage === 'start'" row wrap>
            <v-flex offset-xs1 xs6>
              <v-text-field label="Payment Name" v-model="name" />
            </v-flex>
            <v-flex offset-xs1 xs6>
              <v-select :items="enabledCoins" v-model="selectedCoin" label="Select Coin"></v-select>
            </v-flex>
            <v-flex offset-xs1 xs6>
              <v-textarea outlined v-model="csv" label="CSV" />
            </v-flex>
            <v-flex offset-xs1 xs11 class="text-xs-left">
              <v-btn :disabled="!canPreview" @click="preview" color="primary"> Preview </v-btn>
            </v-flex>
          </v-layout>
          <v-layout v-if="stage === 'preview'" row wrap>
            <v-flex xs12>
              <bulk-payment-details :bulk-payment="previewData" />
            </v-flex>
            <v-flex offset-xs1 xs11 class="text-xs-left">
              <v-btn @click="back" color="primary"> Back </v-btn>
              <v-btn :disabled="!canSave" @click="save" color="primary"> Create Draft Payment </v-btn>
            </v-flex>
          </v-layout>
        </template>
      </v-container>
    </ba-dialog>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import _ from 'lodash';

import { MUT_SNACKBAR } from '../../store';
import BulkPaymentDetails from './BulkPaymentDetails';

export default {
  components: { BulkPaymentDetails },
  props: [],
  data() {
    return {
      dialog: false,
      name: '',
      csv: '',
      selectedCoin: '',
      isLoading: false,
      previewLoading: false,
      wallets: [],
      stage: 'start',
      previewData: {},
    };
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    wallets: {
      query: gql`
        query GetWallets($orgId: ID!) {
          wallets(orgId: $orgId) {
            balance {
              balances {
                coin
                value
              }
            }
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
  },
  computed: {
    canPreview() {
      return this.name && this.csv && this.selectedCoin;
    },
    enabledCoins() {
      const coins = this.wallets.map((m) => m.balance.balances.map((b) => b.coin));
      const enabled = _.flatten(coins);
      const items = new Set(enabled);
      return Array.from(items);
    },
    canSave() {
      return !this.previewData.hasErrors;
    },
  },
  methods: {
    resetForm() {
      this.name = '';
      this.csv = '';
      this.selectedCoin = '';
      this.stage = 'start';
      this.previewData = {};
    },
    back() {
      this.previewData = {};
      this.stage = 'start';
    },
    showErrorSnackbar(errorText) {
      this.$store.commit(MUT_SNACKBAR, {
        message: errorText,
        color: 'error',
      });
    },
    validateCsv() {
      /** @type {string} */
      const csv = this.csv;
      const lines = csv.split('\n');
      for (let i = 0; i < lines.length; i++) {
        if (i === 0) {
          if (!/^(address|\s*"address"\s*),(amount|\s*"amount"\s*),(memo|\s*"memo"\s*)$/.test(lines[i])) {
            this.showErrorSnackbar('CSV must have 3 headers: "address", "amount", and "memo"');
            return false;
          }
        } else {
          if (!/^(("[^,"]*"|[^,"]*),){2}("[^"]*"|[^,"]*)$/.test(lines[i])) {
            this.showErrorSnackbar(`Line ${i + 1} of CSV is invalid`);
            return false;
          }
        }
      }
      return true;
    },
    async preview() {
      if (!this.validateCsv()) {
        return;
      }

      this.previewLoading = true;

      const vars = {
        orgId: this.$store.state.currentOrg.id,
        csv: this.csv,
        coin: this.selectedCoin,
        name: this.name,
      };
      try {
        const res = await this.$apollo.query({
          query: gql`
            query createBulkPaymentPreview($orgId: ID!, $coin: Coins!, $name: String!, $csv: String!) {
              createBulkPaymentPreview(orgId: $orgId, coin: $coin, name: $name, csv: $csv) {
                name
                coin
                hasErrors
                payments {
                  address
                  amount
                  memo
                  errors
                }
              }
            }
          `,
          // Parameters
          variables: vars,
          fetchPolicy: 'network-only',
        });
        if (res.data && res.data.createBulkPaymentPreview) {
          this.previewData = res.data.createBulkPaymentPreview;
          this.stage = 'preview';
        } else {
          this.$store.commit(MUT_SNACKBAR, {
            color: 'error',
            message: res.errors.join('; '),
          });
        }
      } catch (e) {
        this.$store.commit(MUT_SNACKBAR, {
          color: 'error',
          message: 'Problem Previewing: ' + e.message,
        });
      }

      this.previewLoading = false;
    },
    closeDialog() {
      this.$emit('refresh');
      this.dialog = false;
    },
    async save() {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        csv: this.csv,
        coin: this.selectedCoin,
        name: this.name,
      };
      try {
        const res = await this.$apollo.mutate({
          // Query
          mutation: gql`
            mutation ($orgId: ID!, $coin: Coins!, $name: String!, $csv: String!) {
              createBulkPayment(orgId: $orgId, coin: $coin, name: $name, csv: $csv) {
                success
                errors
              }
            }
          `,
          // Parameters
          variables: vars,
        });
        if (res.data.createBulkPayment && res.data.createBulkPayment.success) {
          this.$emit('refresh');
          this.$store.commit(MUT_SNACKBAR, {
            color: 'success',
            message: 'Bulk payment created successfully',
          });
          this.resetForm();
          this.dialog = false;
        } else {
          this.$store.commit(MUT_SNACKBAR, {
            color: 'error',
            message: res.errors,
          });
        }
      } catch (err) {
        console.error(err);
        this.$store.commit(MUT_SNACKBAR, {
          color: 'error',
          message: err.message,
        });
      }
    },
  },
};
</script>
<style>
ul.errors {
  list-style: none;
}

ul.errors > li {
  color: indianred;
}
</style>
