import BN from 'bn.js';
import { Transaction as EthereumTx } from 'ethereumjs-tx';
import { BigNumber } from 'mathjs';
import TrezorConnect from 'trezor-connect';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

import { baConfig } from '../../config';
import { BitwaveMultiSendContract } from '../contracts/bitwaveMultisendContract';
import { orchidAbi, orchidAddress } from '../contracts/orchid';
import { assertDefined, isErrorLike } from './guards';

export function connectTrezor(currency: string, path: string) {
  TrezorConnect.manifest({
    email: 'info@bitwave.io',
    appUrl: 'https://app.bitwave.io',
  });

  if (currency === 'BTC') {
    return connectTrezorBTC(path);
  } else if (currency === 'ETH') {
    return connectTrezorETH(path);
  }
}

export async function connectTrezorBTC(path: any) {
  const result = await TrezorConnect.getAccountInfo(path);
  if (result.success) {
    const path = result.payload.path;
    const xpub = result.payload.legacyXpub;
    console.log('Account ID: ', path);
    console.log('Serialized path: ', xpub);
  } else {
    throw new Error(result.payload.error);
  }
}

export async function connectTrezorETH(path: string) {
  const params = {
    bundle: [
      { path: path + '/0', showOnTrezor: false },
      { path: path + '/1', showOnTrezor: false },
      { path: path + '/2', showOnTrezor: false },
      { path: path + '/3', showOnTrezor: false },
      { path: path + '/4', showOnTrezor: false },
    ],
  };

  const result = await TrezorConnect.ethereumGetAddress(params);
  if (!result.success) {
    throw new Error(result.payload.error);
  }
  return result.payload.map((m) => {
    return {
      address: m.address,
      path: m.serializedPath,
    };
  });
}

export async function simpleSendTrezorETH(
  path: string,
  amount: BN,
  fromAddress: string,
  toAddress: string,
  gasLimit: number,
  gasPrice: number
): Promise<{ success: true; transactionId: string } | { success: false; error: string } | undefined> {
  assertDefined(baConfig.ethRpcUrl);
  const web3 = new Web3(baConfig.ethRpcUrl);
  const nonce = await web3.eth.getTransactionCount(fromAddress);
  const gl = web3.utils.toHex(gasLimit);
  const amountInWei = web3.utils.toWei(amount, 'ether');
  const amt = web3.utils.toHex(amountInWei);
  const gasPriceInWei = web3.utils.toWei(gasPrice.toString(), 'gwei');
  const gp = web3.utils.toHex(gasPriceInWei);
  const n = web3.utils.toHex(nonce);
  const t = {
    to: toAddress,
    value: amt, // "0xf4240",
    // data: "0x01",
    chainId: Number(baConfig.ethChainId),
    nonce: n,
    gasLimit: gl, // "0x5208",
    gasPrice: gp, // "0xbebc200"
  };

  const params = {
    path,
    transaction: t,
  };

  TrezorConnect.manifest({
    email: 'info@bitwave.io',
    appUrl: 'https://app.bitwave.io',
  });

  const result = await TrezorConnect.ethereumSignTransaction(params);
  if (result.success) {
    const signedTransaction = {
      ...t,
      r: Buffer.from(result.payload.r.substring(2), 'hex'),
      s: Buffer.from(result.payload.s.substring(2), 'hex'),
      v: Buffer.from(result.payload.v.substring(2), 'hex'),
    };

    const tx = new EthereumTx(signedTransaction, {
      chain: baConfig.ethChain,
      hardfork: 'petersburg',
    });
    const serializedTx = tx.serialize();
    const rawTx = '0x' + serializedTx.toString('hex');

    // transactionCopy.v= "0x0" + chainId.toString(16)

    try {
      const sent = await web3.eth.sendSignedTransaction(rawTx);
      console.log(sent);
      return {
        success: true,
        transactionId: sent.transactionHash,
      };
    } catch (err) {
      return {
        success: false,
        error: isErrorLike(err) ? err.message : JSON.stringify(err),
      };
    }
  } else {
    // snackbar
  }
}

export async function deployMultisendContractTrezor(
  path: string,
  fromAddress: string,
  gasLimit: number,
  gasPrice: number
) {
  assertDefined(baConfig.ethRpcUrl);
  const web3 = new Web3(baConfig.ethRpcUrl);
  const nonce = await web3.eth.getTransactionCount(fromAddress);
  const gl = web3.utils.toHex(gasLimit);
  const amt = web3.utils.toHex(0);
  const gasPriceInWei = web3.utils.toWei(gasPrice.toString(), 'gwei');
  const gp = web3.utils.toHex(gasPriceInWei);
  const n = web3.utils.toHex(nonce);
  const contract = new web3.eth.Contract(BitwaveMultiSendContract.abi);
  const deployAction = contract.deploy({
    data: BitwaveMultiSendContract.bytecode,
    arguments: [],
  });
  // const estimate = await deployAction.estimateGas();

  // Get contract data
  const contractData = deployAction.encodeABI();

  const t = {
    to: '',
    value: amt,
    data: contractData,
    chainId: Number(baConfig.ethChainId),
    nonce: n,
    gasLimit: gl, // "0x5208",
    gasPrice: gp, // "0xbebc200"
  };

  const params = {
    path,
    transaction: t,
  };

  TrezorConnect.manifest({
    email: 'info@bitwave.io',
    appUrl: 'https://app.bitwave.io',
  });

  const result = await TrezorConnect.ethereumSignTransaction(params);
  if (result.success) {
    const signedTransaction = {
      ...t,
      r: Buffer.from(result.payload.r.substring(2), 'hex'),
      s: Buffer.from(result.payload.s.substring(2), 'hex'),
      v: Buffer.from(result.payload.v.substring(2), 'hex'),
      to: undefined,
    };

    delete signedTransaction.to;

    const tx = new EthereumTx(signedTransaction, {
      chain: baConfig.ethChain,
      hardfork: 'petersburg',
    });

    const serializedTx = tx.serialize();
    const rawTx = '0x' + serializedTx.toString('hex');

    try {
      const created = await web3.eth.sendSignedTransaction(rawTx);
      return {
        success: true,
        transactionId: created.transactionHash,
        contractAddress: created.contractAddress,
      };
    } catch (err) {
      console.log(err);
      return {
        success: false,
        error: isErrorLike(err) ? err.message : JSON.stringify(err),
      };
    }
  } else {
    // snackbar
  }
}

export async function sendTrezorERC20Token(
  abi: AbiItem[],
  contractAddress: string,
  path: string,
  amount: BN,
  fromAddress: string,
  toAddress: string,
  gasLimit: number,
  gasPrice: number
) {
  assertDefined(baConfig.ethRpcUrl);
  const web3 = new Web3(baConfig.ethRpcUrl);
  const nonce = await web3.eth.getTransactionCount(fromAddress);

  const amountInBaseUnit = web3.utils.toWei(amount, 'ether');

  const orchid = new web3.eth.Contract(abi, contractAddress, {
    from: fromAddress,
  });
  const action = orchid.methods.transfer(toAddress, amountInBaseUnit);
  const actionData = action.encodeABI();

  const gl = web3.utils.toHex(gasLimit);
  // const amountInWei = web3.utils.toWei(amount, 'ether');
  // const amt = web3.utils.toHex(amountInWei);
  const gasPriceInWei = web3.utils.toWei(gasPrice.toString(), 'gwei');
  const gp = web3.utils.toHex(gasPriceInWei);
  const n = web3.utils.toHex(nonce);
  const t = {
    to: contractAddress,
    value: '0x0', // "0xf4240",
    data: actionData,
    chainId: Number(baConfig.ethChainId),
    nonce: n,
    gasLimit: gl, // "0x5208",
    gasPrice: gp, // "0xbebc200"
  };

  const params = {
    path,
    transaction: t,
  };

  TrezorConnect.manifest({
    email: 'info@bitwave.io',
    appUrl: 'https://app.bitwave.io',
  });

  const result = await TrezorConnect.ethereumSignTransaction(params);
  if (result.success) {
    const signedTransaction = {
      ...t,
      r: Buffer.from(result.payload.r.substring(2), 'hex'),
      s: Buffer.from(result.payload.s.substring(2), 'hex'),
      v: Buffer.from(result.payload.v.substring(2), 'hex'),
    };

    const tx = new EthereumTx(signedTransaction, {
      chain: baConfig.ethChain,
      hardfork: 'petersburg',
    });
    const serializedTx = tx.serialize();
    const rawTx = '0x' + serializedTx.toString('hex');

    // transactionCopy.v= "0x0" + chainId.toString(16)

    try {
      const sent = await web3.eth.sendSignedTransaction(rawTx);
      console.log(sent);
      return {
        success: true,
        transactionId: sent.transactionHash,
      };
    } catch (err) {
      return {
        success: false,
        error: isErrorLike(err) ? err.message : JSON.stringify(err),
      };
    }
  } else {
    return {
      success: false,
      error: result.payload.error,
    };
    // snackbar
  }
}

export async function multiSendEth(
  path: string,
  total: BN,
  fromAddress: string,
  multiSendContractAddress: string,
  toAddresses: string,
  toValuesEth: BN[],
  gasLimit: number,
  gasPrice: number
) {
  assertDefined(baConfig.ethRpcUrl);
  const web3 = new Web3(baConfig.ethRpcUrl);
  const nonce = await web3.eth.getTransactionCount(fromAddress);

  const toValuesInWei = toValuesEth.map((m) => web3.utils.toWei(m, 'ether'));

  const valueInWei = web3.utils.toWei(total, 'ether');

  const multisendContract = new web3.eth.Contract(BitwaveMultiSendContract.abi, multiSendContractAddress, {
    from: fromAddress,
  });
  const action = multisendContract.methods.sendEth(toAddresses, toValuesInWei);
  const actionData = action.encodeABI();

  const gl = web3.utils.toHex(gasLimit);
  const gasPriceInWei = web3.utils.toWei(gasPrice.toString(), 'gwei');
  const gp = web3.utils.toHex(gasPriceInWei);
  const n = web3.utils.toHex(nonce);
  const v = web3.utils.toHex(valueInWei);
  const t = {
    to: multiSendContractAddress,
    value: v, // "0xf4240",
    data: actionData,
    chainId: Number(baConfig.ethChainId),
    nonce: n,
    gasLimit: gl, // "0x5208",
    gasPrice: gp, // "0xbebc200"
  };

  const params = {
    path,
    transaction: t,
  };

  TrezorConnect.manifest({
    email: 'info@bitwave.io',
    appUrl: 'https://app.bitwave.io',
  });

  const result = await TrezorConnect.ethereumSignTransaction(params);
  if (result.success) {
    const signedTransaction = {
      ...t,
      r: Buffer.from(result.payload.r.substring(2), 'hex'),
      s: Buffer.from(result.payload.s.substring(2), 'hex'),
      v: Buffer.from(result.payload.v.substring(2), 'hex'),
    };

    const tx = new EthereumTx(signedTransaction, {
      chain: baConfig.ethChain,
      hardfork: 'petersburg',
    });
    const serializedTx = tx.serialize();
    const rawTx = '0x' + serializedTx.toString('hex');

    // transactionCopy.v= "0x0" + chainId.toString(16)

    try {
      const sent = await web3.eth.sendSignedTransaction(rawTx);
      console.log(sent);
      return {
        success: true,
        transactionId: sent.transactionHash,
      };
    } catch (err) {
      return {
        success: false,
        error: isErrorLike(err) ? err.message : JSON.stringify(err),
      };
    }
  } else {
    return {
      success: false,
      error: result.payload.error,
    };
    // snackbar
  }
}

export async function approveOxt(
  path: string,
  total: string,
  fromAddress: string,
  multiSendContractAddress: string,
  gasLimit: number,
  gasPrice: number
) {
  assertDefined(baConfig.ethRpcUrl);
  const web3 = new Web3(baConfig.ethRpcUrl);
  const nonce = await web3.eth.getTransactionCount(fromAddress);

  const valueInWei = web3.utils.toWei(total.toString(), 'ether');
  const orchid = new web3.eth.Contract(orchidAbi, orchidAddress, {
    from: fromAddress,
  });

  const action = orchid.methods.approve(multiSendContractAddress, valueInWei);
  const actionData = action.encodeABI();

  const gl = web3.utils.toHex(gasLimit);
  const gasPriceInWei = web3.utils.toWei(gasPrice.toString(), 'gwei');
  const gp = web3.utils.toHex(gasPriceInWei);
  const n = web3.utils.toHex(nonce);
  const v = web3.utils.toHex(0);
  const t = {
    to: orchidAddress,
    value: v, // "0xf4240",
    data: actionData,
    chainId: Number(baConfig.ethChainId),
    nonce: n,
    gasLimit: gl, // "0x5208",
    gasPrice: gp, // "0xbebc200"
  };

  const params = {
    path,
    transaction: t,
  };

  TrezorConnect.manifest({
    email: 'info@bitwave.io',
    appUrl: 'https://app.bitwave.io',
  });

  const result = await TrezorConnect.ethereumSignTransaction(params);
  if (result.success) {
    const signedTransaction = {
      ...t,
      r: Buffer.from(result.payload.r.substring(2), 'hex'),
      s: Buffer.from(result.payload.s.substring(2), 'hex'),
      v: Buffer.from(result.payload.v.substring(2), 'hex'),
    };

    const tx = new EthereumTx(signedTransaction, {
      chain: baConfig.ethChain,
      hardfork: 'petersburg',
    });
    const serializedTx = tx.serialize();
    const rawTx = '0x' + serializedTx.toString('hex');

    // transactionCopy.v= "0x0" + chainId.toString(16)

    try {
      const sent = await web3.eth.sendSignedTransaction(rawTx);
      console.log(sent);
      return {
        success: true,
        transactionId: sent.transactionHash,
      };
    } catch (err) {
      return {
        success: false,
        error: isErrorLike(err) ? err.message : JSON.stringify(err),
      };
    }
  } else {
    return {
      success: false,
      error: result.payload.error,
    };
    // snackbar
  }
}

export async function multiSendOxt(
  path: string,
  total: string,
  fromAddress: string,
  multiSendContractAddress: string,
  toAddresses: string[],
  toValuesEth: string[],
  gasLimit: number,
  gasPrice: number
) {
  assertDefined(baConfig.ethRpcUrl);
  const web3 = new Web3(baConfig.ethRpcUrl);
  const nonce = await web3.eth.getTransactionCount(fromAddress);

  const toValuesInWei = toValuesEth.map((m) => web3.utils.toWei(m.toString(), 'ether'));
  //
  // const valueInWei = web3.utils.toWei(total, "ether");

  const multisendContract = new web3.eth.Contract(BitwaveMultiSendContract.abi, multiSendContractAddress, {
    from: fromAddress,
  });
  const action = multisendContract.methods.sendErc20(orchidAddress, toAddresses, toValuesInWei);
  const actionData = action.encodeABI();

  const gl = web3.utils.toHex(gasLimit);
  const gasPriceInWei = web3.utils.toWei(gasPrice.toString(), 'gwei');
  const gp = web3.utils.toHex(gasPriceInWei);
  const n = web3.utils.toHex(nonce);
  const v = web3.utils.toHex(0);
  const t = {
    to: multiSendContractAddress,
    value: v, // "0xf4240",
    data: actionData,
    chainId: Number(baConfig.ethChainId),
    nonce: n,
    gasLimit: gl, // "0x5208",
    gasPrice: gp, // "0xbebc200"
  };

  const params = {
    path,
    transaction: t,
  };

  TrezorConnect.manifest({
    email: 'info@bitwave.io',
    appUrl: 'https://app.bitwave.io',
  });

  const result = await TrezorConnect.ethereumSignTransaction(params);
  if (result.success) {
    const signedTransaction = {
      ...t,
      r: Buffer.from(result.payload.r.substring(2), 'hex'),
      s: Buffer.from(result.payload.s.substring(2), 'hex'),
      v: Buffer.from(result.payload.v.substring(2), 'hex'),
    };

    const tx = new EthereumTx(signedTransaction, {
      chain: baConfig.ethChain,
      hardfork: 'petersburg',
    });
    const serializedTx = tx.serialize();
    const rawTx = '0x' + serializedTx.toString('hex');

    // transactionCopy.v= "0x0" + chainId.toString(16)

    try {
      const sent = await web3.eth.sendSignedTransaction(rawTx);
      console.log(sent);
      return {
        success: true,
        transactionId: sent.transactionHash,
      };
    } catch (err) {
      return {
        success: false,
        error: isErrorLike(err) ? err.message : JSON.stringify(err),
      };
    }
  } else {
    return {
      success: false,
      error: result.payload.error,
    };
    // snackbar
  }
}
