














import Component from 'vue-class-component';

import { BaseVue } from '../../BaseVue';
import BulkPayment from '../../components/vendors/BulkSend.vue';
@Component({
  components: {
    BulkPayment,
  },
})
export default class BulkPaymentClass extends BaseVue {
  public selectedTab = 0;
}
